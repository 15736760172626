import { createContext,useState } from "react";

export const ElsoContext = createContext({
    loginTimeout:null,
    setLoginTimeout: () => null
})

export const ElsoProvider = ({children}) => {
    const [loginTimeout,setLoginTimeout]=useState(null)
    const value={loginTimeout,setLoginTimeout}
    return <ElsoContext.Provider value={value}>{children}</ElsoContext.Provider>
}