import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { getNestedValue } from "./UtilsVSM"
import Igaz from "../assets/gui-check-yes-svgrepo-com.svg"
import Hamis from "../assets/dash-svgrepo-com.svg"
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import { EditIkon } from "../assets/EditIkon"
import {TypeColumn} from "@inovua/reactdatagrid-community/types/TypeColumn.d"
import { ReactNode } from 'react'
import React from 'react'
import { TypeFilterTypes, TypeFilterValue, TypeSingleFilterValue } from '@inovua/reactdatagrid-community/types'
import * as UtilsVSM from "./UtilsVSM"
import StringFilter from '@inovua/reactdatagrid-community/StringFilter'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'

export type RenderFunction = (data: any) => React.ReactNode;

export type RenderFunctions = { [key in AkciorendereloNev | RendereloNev]?: RenderFunction }

/**
 * A JSON.stringify és a parse jól kezeli.
 */
export enum OszlopTipus {
    STRING="STRING",
    NUMBER="NUMBER",
    BOOLEAN="BOOLEAN",
    DATE="DATE"
}

/**
 * Segítségével nagyon könnyen és gyorsan lehet megadni egy oszlop leírását,
 * végül is csak arra használom, hogy a ColumnDefinition-t könnyen elő tudjam 
 * állítani vele. Amit meg arra használok, hogy az OszlopTipus-t könnyen 
 * elő tudjam állítani vele lásd lent és lent.
 * 
 * @interface ShortColumnDefinition
 * @property {string} p - property
 * @property {string} l - label
 * @property {string} r - renderer
 * @property {boolean} sf - show filter
 * @property {OszlopTipus} t - type
 */                
export interface ShortColumnDefinition  {
    p:string,
    l?:string,
    r?: string | AkciorendereloNev | RendereloNev,
    sf?:boolean,
    t?:OszlopTipus
}



export type OszlopKonfiguracio = TypeColumn & {
    nestedprop:string,
    /** TODO: a típust pontosan be kell állítani */
    filterEditor?:any,
    /** 
     * Nem biztos, hogy itt kell definiálni talán már a szülőben definiálva van, 
     * TODO: ellenőrizni kell!
     * https://reactdatagrid.io/docs/api-reference#props-columns-showColumnMenuFilterOptions
     * 
     */
    showColumnMenuFilterOptions?:boolean|undefined,
    name:string
    prop:string,
    tipus:OszlopTipus,
    showFilter:boolean
    rendereloNev: string | AkciorendereloNev | RendereloNev
}

export enum AkciorendereloNev {
    view="view",
    datasheet="datasheet",
    remove="remove"
}

export enum RendereloNev {
    multiPropStringRender="multiPropStringRender",
    multiPropBooleanRender="multiPropBooleanRender"
}

export const createRendersWithHandlers = (
    obj:
    {[key in AkciorendereloNev]?:(obj:any)=> void}
): RenderFunctions => {
    let rst:RenderFunctions={}
    if(obj[AkciorendereloNev.view]!=null) {
        const handler=obj[AkciorendereloNev.view]
        rst[AkciorendereloNev.view]=RenderFactory.createClickableRender({
            label: <EditIkon label="view" szelesseg={70} height="16" backgroundColor="#f7941d" />,
            handleClickEvent: handler
        })
    }
    if(obj[AkciorendereloNev.datasheet]!=null) {
        const handler=obj[AkciorendereloNev.datasheet]
        rst[AkciorendereloNev.datasheet]=RenderFactory.createClickableRender({
            label: <EditIkon label="edit" height="16" backgroundColor="#f7941d" />,
            handleClickEvent: handler
        })
    }    
    if(obj[AkciorendereloNev.remove]!=null) {
        const handler=obj[AkciorendereloNev.remove]
        rst[AkciorendereloNev.remove]=RenderFactory.createClickableRender({
            label: <EditIkon label="delete" height="16" backgroundColor="#E6582B" />,
            handleClickEvent: handler
        })
    }     
    return rst;
}

export const createRenders = (
    names:
    Array<RendereloNev>
): RenderFunctions => {
    let rst:RenderFunctions={}
    if(names[RendereloNev.multiPropStringRender]!=null) {
        rst[RendereloNev.multiPropStringRender]=RenderFactory.createMultiPropStringRender()
    }
    if(names[RendereloNev.multiPropBooleanRender]!=null) {
        rst[RendereloNev.multiPropBooleanRender]=RenderFactory.createMultiPropBooleanRender()
    }      
    return rst;
}



export class RenderFactory {

    static createClickableRender({ 
        label, 
        handleClickEvent 
    }: { 
        label: JSX.Element; 
        handleClickEvent: (dataID: string|null) => void }
    ): RenderFunction {
        /** https://reactdatagrid.io/docs/#custom-rendering */
        return (d) => {
            return (
                <a
                    href="#"
                    data-id={d.data.id}
                    className="text-center d-block"
                    onClick={(e) => {
                        const dataID = e.currentTarget.getAttribute("data-id")
                        e.preventDefault()
                        handleClickEvent(dataID)
                    }}
                >{label}
                </a>
            )
        }
    }

    static createMultiPropStringRender = () : RenderFunction => {
        /** https://reactdatagrid.io/docs/#custom-rendering */
        return ({ cellProps, data }) => {
            const v = getNestedValue(data, cellProps.nestedprop)
            return v ? v.toString() : v;
        }
    }

    static createMultiPropBooleanRender = () : RenderFunction => {
        /** https://reactdatagrid.io/docs/#custom-rendering */
        return ({ cellProps, data }) => {
            return (
                <div className="text-center">
                    <img
                        src={getNestedValue(data, cellProps.nestedprop) ? Igaz : Hamis}
                        style={{ width: "15px" }} />
                </div>
            )
        }
    }

}




/**
 * TODO: kidolgozando
 * @param konfig 
 * @returns 
 */
export function convertShortColumnDefinitionToOszlopKonfiguracio(
    konfig:ShortColumnDefinition | string,
    renderelok: RenderFunctions
): OszlopKonfiguracio {
    if(typeof konfig==="string") {
        const rendereloEsNev=getRendereloEsNev({
            rendereloNev:RendereloNev.multiPropStringRender,
        })        
        const oszlopKonfiguracio:OszlopKonfiguracio = {
            prop:konfig,
            name: konfig,
            header: konfig,
            nestedprop: konfig,
            visible: true,
            showFilter:false,
            width: konfig.length * 10 + 30,
            headerAlign: 'center',
            showColumnMenuFilterOptions: false,
            rendereloNev: rendereloEsNev.rendereloNev,
            /** https://reactdatagrid.io/docs/#custom-rendering */
            render: rendereloEsNev.renderelo,
            sort: getSortByTipus(OszlopTipus.STRING),
            tipus:OszlopTipus.STRING,
            filterEditor: getFilterEditor(OszlopTipus.STRING)
        }
        return oszlopKonfiguracio
    } else {
        let { 
            p:prop, 
            l:label=prop, 
            r:rendereloNev, 
            sf:showFilter,
            t:tipus 
        }=konfig
        label = label ? label : prop
        if(!tipus)
            tipus=OszlopTipus.STRING
        const rendereloEsNev=getRendereloEsNev({
                renderelok:renderelok,
                rendereloNev:rendereloNev,
                tipus:tipus
            })
        const oszlopKonfiguracio:OszlopKonfiguracio = {
            prop:prop,
            name: prop,
            header: label,
            nestedprop: prop,
            showFilter:showFilter || false,
            visible: true,
            width: label.length * 10 + 30,
            headerAlign: 'center',
            showColumnMenuFilterOptions: true ,
            rendereloNev: rendereloEsNev.rendereloNev,
            /** https://reactdatagrid.io/docs/#custom-rendering */
            render: rendereloEsNev.renderelo,
            sort: getSortByTipus(tipus),
            tipus:tipus,
            filterEditor: getFilterEditor(tipus)
        }
        return oszlopKonfiguracio
    }
}

export function rebuildOszlopKonfiguracio(
    konfig:OszlopKonfiguracio,
    renderelok:RenderFunctions
) {
    return {
        ...konfig,
        render: getRendereloEsNev({
            renderelok:renderelok,
            rendereloNev:konfig.rendereloNev,
            tipus:konfig.tipus
        }).renderelo,
        sort: getSortByTipus(konfig.tipus),
        filterEditor: getFilterEditor(konfig.tipus)
    }
}


function getSortByTipus(
    tipus:OszlopTipus | undefined | null
) : (
    a: any, 
    b: any
) => number {
    if(tipus==null || tipus==undefined)
        return UtilsVSM.nullSafeStringComparator
    switch(tipus) {
        case OszlopTipus.STRING:
            return UtilsVSM.nullSafeStringComparator
        case OszlopTipus.BOOLEAN:
            return UtilsVSM.nullSafeBooleanComparator
        case OszlopTipus.NUMBER:
            return UtilsVSM.nullSafeNumberComparator
        default:
            return UtilsVSM.nullSafeStringComparator
    }
}


/**
 * A renderelok listajabol valaszt ki egyet a nev alapjan vagy egy default 
 * erteket rendel hozza ebbol a modulbol a tipus szerint
 */
function getRendereloEsNev({
    renderelok,
    rendereloNev,
    tipus,
}:{
    renderelok?:RenderFunctions | undefined,
    tipus?:OszlopTipus | undefined,
    rendereloNev:string | undefined
}):{renderelo: RenderFunction,rendereloNev:string} {
    if(rendereloNev && renderelok?.[rendereloNev])
        return {
            renderelo:renderelok[rendereloNev],
            rendereloNev:rendereloNev
        }
    if(tipus) 
        switch(tipus) {
            case OszlopTipus.BOOLEAN:
                return {
                    renderelo:RenderFactory.createMultiPropBooleanRender(),
                    rendereloNev:RendereloNev.multiPropBooleanRender
                }                
            case OszlopTipus.DATE:
                return {
                    renderelo:RenderFactory.createMultiPropStringRender(),
                    rendereloNev:RendereloNev.multiPropStringRender
                } 
            case OszlopTipus.STRING: 
            case OszlopTipus.NUMBER:   
            default:
                return {
                    renderelo:RenderFactory.createMultiPropStringRender(),
                    rendereloNev:RendereloNev.multiPropStringRender
                }
        }
    return {
        renderelo:RenderFactory.createMultiPropStringRender(),
        rendereloNev:RendereloNev.multiPropStringRender
    }
}

/**
 * Ez a fejlécben lévő szűrő mező típusáért felel. 
 * A szám típusú oszlopokat is érdemes string szűrő szerkesztővel ellátni,
 * mert az axios valószínűleg string ként adja vissza a számokat.
 * https://reactdatagrid.io/docs/api-reference#props-columns-filterEditor
 * 
 * @inovua/reactdatagrid-enterprise/StringFilter
 * @inovua/reactdatagrid-community/NumberFilter
 * @inovua/reactdatagrid-community/BoolFilter
 * @inovua/reactdatagrid-community/SelectFilter
 * @inovua/reactdatagrid-community/DateFilter
 */
function getFilterEditor(tipus:OszlopTipus | undefined | null) {
    if(tipus==null || tipus==undefined)
        return StringFilter
    switch(tipus) {
        case OszlopTipus.STRING:
            return StringFilter
        case OszlopTipus.BOOLEAN:
            return BoolFilter
        case OszlopTipus.NUMBER:
            // Mert a számok string-ként vannak tárolva javarészt
            //return StringFilter 
            return NumberFilter
        default:
            return StringFilter
    }
}

/**
 * 
 * 
 * https://reactdatagrid.io/docs/filtering#filtering
 * 
 * Pelda:
 * ```
 * const filterValue = [
 * { name: 'name', operator: 'startsWith', type: 'string', value: '' },
 * { name: 'age', operator: 'gte', type: 'number', value: 21 },
 * { name: 'city', operator: 'startsWith', type: 'string', value: '' },
 * {
 *  name: 'birthDate',
 *  operator: 'before',
 *  type: 'date',
 *  value: ''
 *},
 *{ name: 'country', operator: 'eq', type: 'select', value: 'ca' }
 *];
 * ```
 * 
 * <ReactDataGrid ... 
 * 
 *  filterTypes={this.filterTypes}
 *  defaultFilterValue={this.filterValue}
 *
 *  onEnableFilteringChange={this.onEnableFilteringChange}
 *  enableFiltering={this.columnFilterSupported && this.state.allapot.enableFiltering}
 *
 *  onFilterValueChange={this.onFilterValueChanged}
 * 
 * />
 * 
 */
export function createFilterValues(tabledef:(OszlopKonfiguracio | string)[]):TypeFilterValue {
    return tabledef
        .filter((e): e is OszlopKonfiguracio=>typeof e === 'object' && e.showFilter)
        .map(
            ({prop, tipus }) => {
                const fsz:TypeSingleFilterValue = {
                    // name: the name or the id of the column for which the filter is applied. Mandatory.
                    name: prop, 

                    // operator: the current operator to apply. For example, for numbers, 
                    // it can be any of the following: 'gt', 'gte', 'lt', 'lte', 'eq', 'neq'. Mandatory.
                    // Ez a filter operatora
                    operator: getDefaultFilterOperatorType(tipus),

                    // type: the filter type. This determines the operators available for the specified filter. 
                    // Mandatory
                    // Ez a filter típusa
                    type: getFilterTypeForType(tipus),

                    //  value: the current value for filtering. Optional - but most of the times it should be used.
                    // Ez az alapertelmezett ertek az editorban
                    value: null
                }
                return fsz;
            })
}

function getDefaultFilterOperatorType(tipus:OszlopTipus):string {
    switch (tipus) {
        case OszlopTipus.STRING:
            return "contains"
        case OszlopTipus.BOOLEAN:
            return "eq"
        case OszlopTipus.NUMBER:
            return "eq"
        default: 
            return "contains"
    }
}

function getFilterTypeForType(tipus:OszlopTipus):string {
    switch (tipus) {
        case OszlopTipus.STRING:
            return "StringNestedProp"
        case OszlopTipus.BOOLEAN:
            return "BooleanNestedProp"
        case OszlopTipus.NUMBER:
            return "NumberNestedProp"
        default: 
            return "StringNestedProp"
    }
}

/** Az alap filter típusokat kibővítjük itt
 * https://reactdatagrid.io/docs/api-reference#props-filterTypes
 * A lenyeg, hogy az entításból a value-kat az "fn" függvény így a "nested"
 * prop-nak(column) megfelelenően kapja meg!!!
*/
export const createFilterTypes = ():TypeFilterTypes => {
    const defaultFilterTypes=ReactDataGrid.defaultProps.filterTypes
    const getValue=({
        column, // Ez egy oszlopkonfiguráció
        data // Ez a komplett entítás
    })=> {
        return column.nestedprop ?
            getNestedValue(data, column.nestedprop) :
            data[column.name]
    }
    // 
    /**
     * Egy default operator fn funkciojat "boviti ki" az új fn metódussal, 
     * ami a value értéket nested prop-ként (multi prop-ként) nyeri ki az entításból
    */    
    const createExtendedOperator=({filterNev,operatorNev}:{filterNev:string,operatorNev:string})=>{
        return {
            name: operatorNev,
            // Data: ez egy entítás, 
            fn: ({ data, column, filterValue,value }) => {
                if (!filterValue) return true;
                const v=getValue({column,data})
                const fnOrig=defaultFilterTypes[filterNev].operators.filter(e=>e.name==operatorNev)[0].fn
                return fnOrig({ data, column, filterValue,value:v})
            }
        }
    }

    const extendedFilterTypes={
        ...defaultFilterTypes,
        StringNestedProp: {
            name: 'StringNestedProp',
            operators: [
                /**
                 * Kibővítjük az eredeti filterType-ok operatorait az új fn metódussal, ami a value
                 * értéket nested prop-ként (multi prop-ként) nyeri ki az entításból
                */
                ...defaultFilterTypes["string"].operators
                    .map(operator=>createExtendedOperator({filterNev:"string",operatorNev:operator.name})),
            ]
        },
        BooleanNestedProp: {
            name: 'BooleanNestedProp',
            operators: [
                ...defaultFilterTypes["boolean"].operators
                    .map(operator=>createExtendedOperator({filterNev:"boolean",operatorNev:operator.name})),
            ]
        },
        NumberNestedProp: {
            name: 'NumberNestedProp',
            operators: [
                ...defaultFilterTypes["number"].operators
                    .map(operator=>createExtendedOperator({filterNev:"number",operatorNev:operator.name})),
            ]
        }         
    }
    return extendedFilterTypes;
}
