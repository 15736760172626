export function getNestedValue(obj, property) {
    /*console.log("getNestedValue");
    console.log(obj);
    console.log(property);*/
    obj=deepCopy(obj)
    property=deepCopy(property)
    if (obj == null) return null;
    if (property == null) return null;
    return property.split('.').reduce(function (obj, prop) {
        return obj && obj[prop];
    }, obj);
}

function deepCopy(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj; // visszatérünk az eredeti értékkel, ha az nem objektum, vagy null
    }

    let copy = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            copy[key] = deepCopy(obj[key]); // rekurzívan másoljuk az objektumot
        }
    }

    return copy;
}

export function randomInt({min,max}) {
    return Math.floor(Math.random() * (max - min + 1) + min);    
}

export function isEnvDev() {
    console.log("IS ENV DEV: "+(process.env.NODE_ENV == "development"));
    return process.env.NODE_ENV == "development"
}


/**
 * @function objectArrayMultipleSort
 * 
 * ObjektumTomb töbszörös és irányított rendezése.
 * 
 * Pelda: 
 * 
 * let sortedData = multipleSort(data, [
 *    { prop: 'age', direction: 'asc' },
 *    { prop: 'isActive', direction: 'desc' },
 * ]);
 * 
 * @param {any} arr
 * @param {any} props
 * @returns {any}
 */
export function objectArrayMultipleSort(arr, props) {
    arr.sort((a, b) => {
        for (let {
                prop,
                direction
            } of props) {
            let valueA = a[prop];
            let valueB = b[prop];
            let result = 0;

            if (typeof valueA === 'string' && typeof valueB === 'string') {
                result = valueA.localeCompare(valueB);
            } else {
                result = valueA - valueB;
            }

            if (result !== 0) {
                return direction === 'asc' ? result : -result;
            }
        }

        return 0;
    });

    return arr;
}

export function convertSecondsToMinutesAndSeconds(seconds) {
    const minutes = Math.floor(seconds / 60); // Perc kiszámítása
    const remainingSeconds = seconds % 60; // Maradék másodpercek kiszámítása

    // Formázás 2 db digitre a másodpercek értékénél
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    // Visszatérés az eredménnyel formázott string formában
    return `${minutes}:${formattedSeconds}`;
}


/**
 * @function calculateOfficalOvertime
 * null biztos
 * @param {any} value
 * @returns {any}
 */
export function calculateOfficalOvertime(value) {
    return value && !isNaN(value)? (Math.ceil( value * 1.5 * 100) / 100) :null
}
