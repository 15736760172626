import React from 'react';
import { IconProps } from './types';

export const LogoutIcon: React.FC<IconProps> = ({ size = 18, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"

      version="1.1"
      viewBox="-0.5 71.85 513 368.3"
      xmlSpace="preserve"
      width={size}
      height={size}
      fill="currentColor"
      stroke="currentColor"   
      {...rest}
    >
      <g>
        <path d="M512 256L411.826 155.826 411.826 222.609 233.903 222.609 233.903 289.391 411.826 289.391 411.826 356.174z"></path>
        <path d="M333.913 139.13L333.913 72.348 0 72.348 0 439.652 333.913 439.652 333.913 372.87 66.783 372.87 66.783 139.13z"></path>
      </g>
    </svg>
  );
}

export default LogoutIcon;