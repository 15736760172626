import React from 'react';

export interface IconProps extends React.SVGAttributes<HTMLOrSVGElement> {
  size?: number;
}

interface IkonTulajdonsagok extends IconProps {
  label?:string,
  backgroundColor:string,
  textColor?:string
  szelesseg?:number
}

export const EditIkon: React.FC<IkonTulajdonsagok> = ({ 
  height="15", 
  label="???",
  backgroundColor="#E85B2C", 
  textColor="",...rest 
}) => {
  const texthossz=label.length
  const szelesseg=texthossz*12.5+15
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      viewBox={"0 0 "+(szelesseg)+" 23"}
      fill="currentColor"
      {...rest}
    >
      <rect x="0" y="0" width={szelesseg} height="23" rx="10" ry="10" fill={backgroundColor}/>
      <text
        x="15"
        y="17"
        fill="#fff"
        fontFamily="'Quicksand-Bold', 'Quicksand'"
        fontSize="16"
        fontWeight="700"
      >
        {label.toUpperCase()}
      </text>
    </svg>
  );
};

export default EditIkon