import React from 'react';
import { IconProps } from './types';

export const Maximize: React.FC<IconProps> = ({ size = 18, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="currentColor"
      {...rest}
    >
      <g>
        <g fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-419 -440)">
            <g transform="translate(56 160)">
              <path d="M372.45 286.967h3.15v2.005h-3.15v3.008h-2.1v-3.008h-3.15v-2.005h3.15v-3.007h2.1v3.007zM384 298.582L382.515 300l-4.455-4.254 1.485-1.417 4.455 4.253zm-12.6-4.547c-3.473 0-6.3-2.698-6.3-6.015 0-3.316 2.827-6.015 6.3-6.015s6.3 2.699 6.3 6.015c0 3.317-2.827 6.015-6.3 6.015zm0-14.035c-4.639 0-8.4 3.591-8.4 8.02 0 4.43 3.761 8.02 8.4 8.02s8.4-3.59 8.4-8.02c0-4.429-3.761-8.02-8.4-8.02z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};