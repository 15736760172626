import React from 'react';
import { IconProps } from './types';

export const Minimize: React.FC<IconProps> = ({ size = 18, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="currentColor"
      {...rest}
    >
      <g>
        <g fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-99 -480)">
            <g transform="translate(56 160)">
              <path d="M47.325 328.972h8.651v-2.005h-8.65v2.005zM64 338.582L62.47 340l-4.587-4.254 1.529-1.417L64 338.582zm-12.35-4.547c-3.577 0-6.487-2.698-6.487-6.015 0-3.316 2.91-6.015 6.488-6.015 3.577 0 6.488 2.699 6.488 6.015 0 3.317-2.911 6.015-6.488 6.015zm0-14.035c-4.778 0-8.65 3.591-8.65 8.02 0 4.43 3.872 8.02 8.65 8.02 4.778 0 8.652-3.59 8.652-8.02 0-4.429-3.874-8.02-8.651-8.02z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};