
import { ElsoContext } from "./ElsoContext"
import { useEffect, useState,useContext } from 'react'
import { useIdleTimer } from 'react-idle-timer/legacy'
import { useKeycloak } from '@react-keycloak/web';
import { convertSecondsToMinutesAndSeconds } from '../UtilsVSM';

export function UresJaratFigyelo() {

  const { keycloak } = useKeycloak();

  const onIdle = () => {
    keycloak.logout();
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive:()=>{},
    onAction:()=>{},
    timeout: 1_000*60*10,
    throttle: 500
  })

  const {setLoginTimeout}=useContext(ElsoContext)

  useEffect(() => {
    const interval = setInterval(() => {
      const mp=Math.ceil(getRemainingTime() / 1000)
      //console.log(UtilsVSM.convertSecondsToMinutesAndSeconds(mp))
      //setTimeout(mp)
      setLoginTimeout(mp)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })  
  //console.log("Render: uresjarat:"+convertSecondsToMinutesAndSeconds(getRemainingTime()/1000));
  return (<></>)
}