import React from 'react';
import { IconProps } from './types';

export const Website: React.FC<IconProps> = ({ size = 18, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
      fill="currentColor"
      {...rest}
    >
      <path d="M256 0C114.615 0 0 114.615 0 256s114.615 256 256 256 256-114.615 256-256S397.385 0 256 0zm162.275 146h-46.667c-5.365-22.513-12.324-43.213-20.587-61.514 15.786 8.776 30.449 19.797 43.572 32.921A198.054 198.054 0 01418.275 146zM452 256c0 17.108-2.191 33.877-6.414 50h-64.034c1.601-16.172 2.448-32.887 2.448-50s-.847-33.828-2.448-50h64.034c4.223 16.123 6.414 32.892 6.414 50zM256 452c-5.2 0-21.048-10.221-36.844-41.813-6.543-13.087-12.158-27.994-16.752-44.187h107.191c-4.594 16.192-10.208 31.1-16.752 44.187C277.048 441.779 261.2 452 256 452zm-65.187-146c-1.847-16.247-2.813-33.029-2.813-50s.966-33.753 2.813-50h130.374c1.847 16.247 2.813 33.029 2.813 50s-.966 33.753-2.813 50H190.813zM60 256c0-17.108 2.191-33.877 6.414-50h64.034c-1.601 16.172-2.448 32.887-2.448 50s.847 33.828 2.448 50H66.414C62.191 289.877 60 273.108 60 256zM256 60c5.2 0 21.048 10.221 36.844 41.813 6.543 13.087 12.158 27.994 16.752 44.187H202.404c4.594-16.192 10.208-31.1 16.752-44.187C234.952 70.221 250.8 60 256 60zm-95.021 24.486c-8.264 18.301-15.222 39-20.587 61.514H93.725a198.12 198.12 0 0123.682-28.593c13.124-13.124 27.786-24.145 43.572-32.921zM93.725 366h46.667c5.365 22.513 12.324 43.213 20.587 61.514-15.786-8.776-30.449-19.797-43.572-32.921A198.054 198.054 0 0193.725 366zm257.296 61.514c8.264-18.301 15.222-39 20.587-61.514h46.667a198.12 198.12 0 01-23.682 28.593c-13.124 13.124-27.786 24.145-43.572 32.921z"></path>
    </svg>
  );
};