export default function PropLabelMegjelenito ({ 
    p:prop, 
    l:label, 
    ls:labelSize,
    r:render, 
    o:objektum, 
    w:width="100px"
}) {
    if(!objektum) return
    const value = objektum[prop]
    if (!value) return <></>
    return (
        <>
            <div className="d-md-flex mb-2 mb-md-0">
                <div style={{ minWidth: width }} >
                    {label || prop}:
                </div>
                <div className="flex-md-fill ps-md-2" >
                    {render?render(value):value}
                </div>
            </div>

        </>
    )
}