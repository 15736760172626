import moment from "moment";

/**
 * deprecated!!! TODO: eltavolitando
 */
export function getNestedValue(obj, property) {
    obj=deepCopy(obj)
    property=deepCopy(property)
    if (obj == null) return null;
    if (property == null) return null;
    return property.split('.').reduce(function (obj, prop) {
        return obj && obj[prop];
    }, obj);
}


export function getNestedValueWithouDeepCopy(obj:any, property:string) {
    if (obj == null) return null;
    if (property == null) return null;
    return property.split('.').reduce((obj, prop)=> {
        return obj && obj[prop];
    }, obj);
}

function deepCopy(obj) {
    if (typeof obj !== 'object' || obj === null) {
        return obj; // visszatérünk az eredeti értékkel, ha az nem objektum, vagy null
    }

    let copy = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            copy[key] = deepCopy(obj[key]); // rekurzívan másoljuk az objektumot
        }
    }

    return copy;
}

export function randomInt({min,max}) {
    return Math.floor(Math.random() * (max - min + 1) + min);    
}

export function isEnvDev() {
    console.log("IS ENV DEV: "+(process.env.NODE_ENV == "development"));
    return process.env.NODE_ENV == "development"
}


/**
 * @function objectArrayMultipleSort
 * 
 * ObjektumTomb töbszörös és irányított rendezése.
 * 
 * Pelda: 
 * 
 * let sortedData = multipleSort(data, [
 *    { prop: 'age', direction: 'asc' },
 *    { prop: 'isActive', direction: 'desc' },
 * ]);
 * 
 * @param {any} arr
 * @param {any} props
 * @returns {any}
 */
export function objectArrayMultipleSort(arr, props) {
    arr.sort((a, b) => {
        for (let {
                prop,
                direction
            } of props) {
            let valueA = a[prop];
            let valueB = b[prop];
            let result = 0;

            if (typeof valueA === 'string' && typeof valueB === 'string') {
                result = valueA.localeCompare(valueB);
            } else {
                result = valueA - valueB;
            }

            if (result !== 0) {
                return direction === 'asc' ? result : -result;
            }
        }

        return 0;
    });

    return arr;
}

export function convertSecondsToMinutesAndSeconds(seconds) {
    const minutes = Math.floor(seconds / 60); // Perc kiszámítása
    const remainingSeconds = seconds % 60; // Maradék másodpercek kiszámítása

    // Formázás 2 db digitre a másodpercek értékénél
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    // Visszatérés az eredménnyel formázott string formában
    return `${minutes}:${formattedSeconds}`;
}


/**
 * @function calculateOfficalOvertime
 * null biztos
 * @param {any} value
 * @returns {any}
 */
export function calculateOfficalOvertime(value) {
    return value && !isNaN(value)? (Math.ceil( value * 1.5 * 100) / 100) :null
}


export function nullSafeStringComparator(
    a: string | null | undefined, 
    b: string | null | undefined
): number {
    if (a === b) {
        return 0;
    }
    if (a === null || a === undefined) {
        return -1;
    }
    if (b === null || b === undefined) {
        return 1;
    }
    return a.localeCompare(b);
}

export function nullSafeBooleanComparator(
    a: boolean | null | undefined, 
    b: boolean | null | undefined
): number {
    if (a === b) {
        return 0;
    }
    if (a === null || a === undefined) {
        return -1;
    }
    if (b === null || b === undefined) {
        return 1;
    }
    return a ? -1 : 1;
}


export function nullSafeNumberComparator(
    a: number | null | undefined, 
    b: number | null | undefined
): number {
    if (a === b) {
        return 0;
    }
    if (a === null || a === undefined) {
        return -1;
    }
    if (b === null || b === undefined) {
        return 1;
    }
    return a - b;
}


export interface MultiPropMultiTypeComparatorKonfig {
    prop?: string, // Ha nem adod meg akkor a tömb elemei az értékek
    direction?: "desc" | "asc", // default: asc
    type?: "string" | "number" | "date" | "datetime" | "boolean", // default: string
    dateTimeFormat?: string
}

/**
 * const data = [
 *     { name: "John", age: null, obj: { date: "08/12", szin: "kék" }, active: true },
 *     { name: "Alice", age: null, obj:  { date: "08/12", szin: "kék" }, active: false },
 *     { name: "Alice", age: 35, obj: { date: "10/12", szin: "piros" }, active: true }
 * ];
 * 
 * const sortingConfig: MultiPropMultiTypeComparatorKonfig[] = [
 *     { prop: "obj.szin", direction: "desc", type: "string" },
 *     //{ prop: "obj.date", direction: "asc", type: "date",dateTimeFormat:"DD/MM" },
 *     { prop: "name", direction: "asc", type: "string" },
 * ];
 * 
 * data.sort(multiPropMultiTypeComparator(...sortingConfig))
 * data.sort(multiPropMultiTypeComparator(
 *     { prop: "name"},{ prop: "obj.szin" }
 * ))
 * 
 * console.log(data); 
 */
export function multiPropMultiTypeComparator(
    ...konfigs:MultiPropMultiTypeComparatorKonfig[]
) {
    return (a: any, b: any): number => {
        for (const konfig of konfigs) {
            if(!konfig.direction) konfig.direction="asc"
            if(!konfig.type) konfig.type="string"
            const aValue = !konfig.prop?a:getNestedValueWithouDeepCopy(a,konfig.prop);
            const bValue = !konfig.prop?b:getNestedValueWithouDeepCopy(b,konfig.prop);

            if (aValue === null && bValue === null) {
                continue;
            } else if (aValue === null) {
                return konfig.direction === "asc" ? -1 : 1;
            } else if (bValue === null) {
                return konfig.direction === "asc" ? 1 : -1;
            }

            let compareResult: number;

            switch (konfig.type) {
                case "string":
                    compareResult = aValue.localeCompare(bValue);
                    break;                    
                case "date":
                case "datetime":
                    if(konfig.dateTimeFormat) {                       
                        const d1=moment(aValue,konfig.dateTimeFormat)
                        const d2=moment(bValue, konfig.dateTimeFormat)
                        if (d1.isBefore(d2)) {
                            compareResult = -1;
                        } else if (d1.isAfter(d2)) {
                            compareResult = 1;
                        } else {
                            compareResult = 0;
                        }
                    } else {
                        compareResult = aValue.localeCompare(bValue);
                    }
                    break; 
                case "number":
                    compareResult = aValue - bValue;
                    break;
                case "boolean":
                    compareResult = aValue === bValue ? 0 : aValue ? -1 : 1;
                    break;
                default:
                    compareResult = 0;
                    break;
            }

            if (compareResult !== 0) {
                return konfig.direction === "asc" ? compareResult : -compareResult;
            }
        }
        return 0;
    };
}

