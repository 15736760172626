import React from 'react';
import { IconProps } from './types';

export const Config: React.FC<IconProps> = ({ size = 18, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0.05 0 482.1 482.2"
      fill="currentColor"
      {...rest}
    >
      <g>
        <path d="M461.75 205.6l-31-.1c-4.8-25.5-14.8-50.2-29.9-72.5l22.1-22c8-7.9 8-20.8.1-28.8l-22.2-22.3c-7.9-8-20.8-8-28.8-.1l-22.1 22c-22.2-15.2-46.8-25.3-72.3-30.3l.1-31c0-11.2-9.1-20.4-20.3-20.4l-31.5-.1c-11.2 0-20.4 9.1-20.4 20.3l-.1 30.9c-25.6 4.7-50.4 14.6-72.8 29.7l-21.6-21.7c-7.9-8-20.8-8-28.8-.1l-22.3 22.2c-8 7.9-8 20.8-.1 28.8l21.4 21.5c-15.4 22.3-25.7 47.2-30.6 72.9l-30.1-.1c-11.2 0-20.4 9.1-20.4 20.3l-.1 31.5c0 11.2 9.1 20.4 20.3 20.4l30 .1c4.7 25.8 14.8 50.9 30 73.4l-21.2 21c-8 7.9-8 20.8-.1 28.8l22.2 22.3c7.9 8 20.8 8 28.8.1l21.2-21c22.5 15.4 47.4 25.6 73.2 30.4l-.1 30c0 11.2 9.1 20.4 20.3 20.4l31.5.1c11.2 0 20.4-9.1 20.4-20.3l.1-30.1c25.7-4.8 50.6-14.9 73-30.3l21.4 21.5c7.9 8 20.8 8 28.8.1l22.3-22.2c8-7.9 8-20.8.1-28.8l-21.6-21.7c15.2-22.3 25.2-47.1 30.1-72.7l30.9.1c11.2 0 20.4-9.1 20.4-20.3l.1-31.5c0-11.2-9.1-20.4-20.4-20.4zm-220.7 167c-72.6 0-131.5-58.9-131.5-131.5s58.9-131.5 131.5-131.5 131.5 58.9 131.5 131.5-58.9 131.5-131.5 131.5zm0-220.6c-49.2 0-89.1 39.9-89.1 89.1s39.9 89.1 89.1 89.1 89.1-39.9 89.1-89.1-39.9-89.1-89.1-89.1zm0 59.6c-16.3 0-29.5 13.2-29.5 29.5 0 7.1-5.7 12.8-12.8 12.8s-12.8-5.7-12.8-12.8c0-30.4 24.7-55.1 55.1-55.1 7.1 0 12.8 5.7 12.8 12.8s-5.7 12.8-12.8 12.8z"></path>
      </g>
    </svg>
  );
};